import React from "react";
import theme from "theme";
import { Theme, Text, Box, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Ziklo Smart
			</title>
			<meta name={"description"} content={"Mükemmel alanınızı bizimle keşfedin"} />
			<meta property={"og:title"} content={"Ziklo Smart"} />
			<meta property={"og:description"} content={"Mükemmel alanınızı bizimle keşfedin"} />
			<meta property={"og:image"} content={"https://ziklosmart.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ziklosmart.com/img/6466149.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ziklosmart.com/img/6466149.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ziklosmart.com/img/6466149.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ziklosmart.com/img/6466149.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ziklosmart.com/img/6466149.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ziklosmart.com/img/6466149.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" color="--dark" quarkly-title="Contacts-8">
			<Override slot="SectionContent" flex-direction="row" align-items="flex-start" lg-flex-direction="column" />
			<Box
				min-width="100px"
				min-height="70vh"
				width="40%"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				justify-content="flex-start"
				lg-margin="0px 0 30px 0px"
				align-items="flex-start"
				sm-margin="0px 0px 0 0px"
				lg-padding="0px 180px 0px 0px"
				sm-padding="0px 0 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 20px 0px" font="normal 600 38px/1.2 --fontFamily-sansHelvetica" color="--dark">
				Bize Ulaşın
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
					color="--darkD2"
					lg-margin="0px 0px 20px 0px"
					md-width="100%"
				>
					Ama eğer değinmediysek - ister belirli bir konuda açıklama arıyor olun, ister kendi koşullarınıza uygun kişiselleştirilmiş tavsiyeler almak istiyor olun, daha fazla bilgi için bize ulaşmaktan çekinmeyin. Ekibimiz, bilinçli kararlar vermek ve gayrimenkul hedeflerinize ulaşmak için ihtiyaç duyduğunuz desteği ve rehberliği sağlamak için burada.
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				margin="0px 0 0 10%"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-gap="36px 34px"
				md-grid-template-columns="1fr"
				flex-direction="column"
				width="50%"
				lg-margin="0px 0 0 0"
				lg-width="100%"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-darkD2"
					border-radius="4px"
					align-items="center"
					margin="0px 0px 30px 0px"
					lg-margin="0px 0px 10px 0px"
					sm-margin="0px 0px 0 0px"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							color="--dark"
							text-decoration-line="initial"
							font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							Meşhur Assos Dondurması Yahya Usta, 17862
						</Link>
						<Text
							margin="0px 0px 0 0px"
							font="normal 300 16px/1.2 --fontFamily-sans"
							text-align="center"
							color="--darkD2"
							md-margin="0px 0px 0px 0px"
						>
							{" "}Behram/Ayvacık/Çanakkale, Türkiye
						</Text>
					</Box>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-darkD2"
					border-radius="4px"
					align-items="center"
					margin="0px 0px 30px 0px"
					lg-margin="0px 0px 10px 0px"
					sm-margin="0px 0px 0 0px"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							href="tel:+90 532 637 4060"
							color="--dark"
							text-decoration-line="initial"
							font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							+90 532 637 4060
						</Link>
					</Box>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-darkD2"
					border-radius="4px"
					align-items="center"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							href="mailto:admin@ziklosmart.com"
							color="--dark"
							text-decoration-line="initial"
							font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							admin@ziklosmart.com
						</Link>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});